<template>
  <v-dialog v-model='dialog' persistent width='600px'>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn text @click='openDialog()'>
        <v-icon color='neuter' left small>mdi-file-document-outline</v-icon>
        {{ $t('common.titles.nomenclators.downloadTemplate').toUpperCase() }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class='headline secondary t-bw-secondary--text'>
        {{ $t('common.titles.nomenclators.downloadTemplate') }} - {{ $t('survey.label') }}
        <v-spacer />
        <v-btn color="t-bw-secondary" text icon @click="dialogTemplateCSVClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-6">
        <v-row>
          <v-col cols='12'>
            <v-autocomplete
              v-model="template"
              :disabled="loading"
              :items="allTemplates"
              :label="$t('survey.fields.template')"
              :loading='loading'
              :no-data-text="$t('common.fields.noDataAvailable')"
              class='d-flex align-baseline'
              clearable
              outlined
              dense
              color='secondary'
              item-color='secondary'
              item-text="name"
              item-value="id"
              small-chips
              @change="verifyRules($event)"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content v-text='item.name' />
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small>
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols='12'>
            <v-alert
              border='left'
              class='mt-2'
              color='orange'
              dense
              icon='mdi-alert-outline'
              text
              type='warning'
            >
              {{ $t('common.titles.warningUploadTemplate') }}
            </v-alert>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('common.titles.nomenclators.downloadTemplate') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('common.titles.uploadTemplateNote') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="red--text">{{ templateMessage }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn :disabled='loading || !template' :loading='loading' color='primary'
                         elevation="0" class="t-bw-primary--text" small @click='fetchTemplate'>
                    {{ $t('common.buttons.generate') }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import LanguageService from '@/services/LanguajeService'
  import i18n from '@/plugins/i18n'

  export default {
    name: 'DownloadCSVTemplateComponent',

    props: {
      profile: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      dialog: false,
      loading: false,
      templateMessage: '',
      template: null,
      language: LanguageService.getLenguajeName()
    }),

    computed: {
      ...mapGetters({
        allTemplates: 'survey/allTemplates'
      })
    },

    methods:{
      ...mapActions({
        fetchTemplateAction: 'inspections/fetchTemplate',
      }),
      async fetchTemplate() {
        this.loading = true

        await this.fetchTemplateAction([{
          email: this.profile.email,
          importType: 'INSPECTION',
          language: LanguageService.getLenguajeName(),
          data: {
            templateId: this.template
          }
        }, this.$toast]).finally(() => {
          this.loading = false
          this.resetForm() // Reset form after saving
        })
      },
      openDialog() {
        this.$emit('closeMenu', false)
        this.dialog = true
      },
      verifyRules(event) {
        if(event)
          this.templateMessage = ''
        else
          this.templateMessage = i18n.t('common.titles.emptyTemplate')
      },
      dialogTemplateCSVClose() {
        this.dialog = false
        this.resetForm()
      },
      resetForm() {
        this.template = null
        this.templateMessage = ''
        this.language = LanguageService.getLenguajeName()
        this.loading = false
      }
    }
  }
</script>

<style scoped>

</style>